
import { Component, Prop, Vue } from 'vue-property-decorator'
import UniversalCookie from 'universal-cookie'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

@Component
export default class MessageAfterLogin extends Vue {
  @Prop({
    type: Number,
    required: true
  })
  private memberId!: number

  private mounted() {
    const cookie = new UniversalCookie()
    const paths = ['/', '/members']
    this.$nextTick(() => {
      this.handleLoginMessage(cookie, paths)
      this.handleQuitMessage(cookie, paths)
      this.handleUnauthorizedMessage(cookie, paths)
    })
  }

  private handleLoginMessage(cookie: UniversalCookie, paths: string[]) {
    const beforeLoginPath = cookie.get('before_login')
    const loginSuccess = cookie.get('email_login')

    if ((this.memberId || loginSuccess) && beforeLoginPath) {
      this.$store.dispatch('authentication/updateLoginMessage', true)
      this.removeCookies(cookie, ['before_login', 'email_login'], paths)
      this.$nextTick(() => {
        this.displayMessage('authentication/updateLoginMessage', 1500)
      })
    }
  }

  private handleQuitMessage(cookie: UniversalCookie, paths: string[]) {
    const quit = cookie.get('quitMessage')

    if (quit) {
      this.$store.dispatch('settings/updateQuitMessage', true)
      this.removeCookies(cookie, ['quitMessage', 'member_id'], paths)
      this.$nextTick(() => {
        this.displayMessage('settings/updateQuitMessage', 3500)
      })
    }
  }

  private handleUnauthorizedMessage(cookie: UniversalCookie, paths: string[]) {
    const unauthorizedError = cookie.get('unauthorized_error')

    if (unauthorizedError) {
      this.$store.dispatch('authentication/updateUnauthorizedMember', true)
      this.removeCookies(cookie, ['unauthorized_error', 'member_id'], paths)
      this.$nextTick(() => {
        this.displayMessage('authentication/updateUnauthorizedMember', 3000)
      })
    }
  }

  private removeCookies(
    cookie: UniversalCookie,
    cookieNames: string[],
    paths: string[]
  ) {
    cookieNames.forEach((name) => {
      paths.forEach((path) => {
        cookie.remove(name, { path })
      })
    })
  }

  private displayMessage(mutation: string, duration: number) {
    const overlayElement = this.$refs.messageOverlay as HTMLElement
    const windowElement = this.$refs.messageWindow as HTMLElement
    if (windowElement && overlayElement) {
      disableBodyScroll(windowElement)
      setTimeout(() => {
        windowElement.classList.add('fade-out')
        setTimeout(() => {
          overlayElement.classList.add('fade-out')
          setTimeout(() => {
            this.$store.dispatch(mutation, false)
            clearAllBodyScrollLocks()
          }, 500) // 0.5s delay for overlay fade-out
        }, 500) // 0.5s delay for window fade-out
      }, duration)
    }
  }

  private get isOverlayVisible() {
    return this.isLoggedIn || this.quitMessage || this.unauthorizedMemberMessage
  }

  private get isLoggedIn() {
    return this.$store.state.authentication.loginMessage
  }

  private get quitMessage() {
    return this.$store.state.settings.quitMessage
  }

  private get unauthorizedMemberMessage() {
    return this.$store.state.authentication.unauthorizedMemberMessage
  }
}
