import { render, staticRenderFns } from "./MessageAfterLogin.vue?vue&type=template&id=0949fbe0&scoped=true&"
import script from "./MessageAfterLogin.vue?vue&type=script&lang=ts&"
export * from "./MessageAfterLogin.vue?vue&type=script&lang=ts&"
import style0 from "./MessageAfterLogin.vue?vue&type=style&index=0&id=0949fbe0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0949fbe0",
  null
  
)

export default component.exports