var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"name":"fade"}},[(_vm.isOverlayVisible)?_c('div',{ref:"messageOverlay",staticClass:"message-overlay"},[_c('div',{ref:"messageWindow",staticClass:"message-window"},[_c('dl',{staticClass:"message-contents-wrapper"},[_c('dt',{staticClass:"message-image"},[_c('img',{staticClass:"logo-img",attrs:{"src":require("assets/img/svg/logo-icon.svg"),"alt":"logo"}})]),_vm._v(" "),_c('dd',{staticClass:"message-contents"},[_c('div',{class:{
              'message-inner': true,
              fade: _vm.isLoggedIn,
              block: _vm.isLoggedIn
            }},[_c('p',{staticClass:"message-title"},[_vm._v("ログインしました")]),_vm._v(" "),_c('p',{staticClass:"message-text"},[_vm._v("ページを表示する準備をしています。")])]),_vm._v(" "),_c('div',{class:{
              'message-inner': true,
              fade: _vm.quitMessage,
              block: _vm.quitMessage
            }},[_c('p',{staticClass:"message-title"},[_vm._v("退会完了しました")]),_vm._v(" "),_c('p',{staticClass:"message-text"},[_vm._v("今までのご利用ありがとうございました。")])]),_vm._v(" "),_c('div',{class:{
              'message-inner': true,
              fade: _vm.unauthorizedMemberMessage,
              block: _vm.unauthorizedMemberMessage
            }},[_c('p',{staticClass:"message-title"},[_vm._v("\n              ログイン状態が切れましたので、再度ログインお願いします。\n            ")])])])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }